import { RegisterFormRequest } from '../../components/RecordingRequestForm'
import { dbOperatorUrl } from '../../utils/config'
import { Resource, CreateUser, Analytic } from '../../utils/types/dataStructures'
import { SolutionDesign, SolutionDesignGcp } from '../../utils/types/vmRequests'
import { apiCaller } from '../apiCaller'

/**
 *
 * @param request
 * @returns
 */
export async function saveFormRequest (request: RegisterFormRequest): Promise<any> {
  const response = await apiCaller({
    url: `${dbOperatorUrl}/request`,
    method: 'POST',
    data: JSON.stringify(request)
  })

  return response.json()
}

export async function createNewUserEntry (request: CreateUser): Promise<any> {
  const response = await apiCaller({
    url: `${dbOperatorUrl}/user`,
    method: 'POST',
    data: JSON.stringify(request)
  })
  return response.json()
}

/**
 *
 * @returns
 */
export async function getRequests (): Promise<any> {
  const response = await apiCaller({
    url: `${dbOperatorUrl}/request`,
    method: 'GET'
  })

  return response.json()
}

/**
 *
 * @returns
 */
export async function getRequestsById (id: number): Promise<any> {
  const response = await apiCaller({
    url: `${dbOperatorUrl}/request/${id}`,
    method: 'GET'
  })

  return response.json()
}

export async function getRequestsByType (requestType: string): Promise<any> {
  const response = await apiCaller({
    url: `${dbOperatorUrl}/request/type/${requestType}`,
    method: 'GET'
  })

  return response.json()
}

export async function getResources (): Promise<any> {
  const response = await apiCaller({
    url: `${dbOperatorUrl}/resource`,
    method: 'GET'
  })
  return response.json()
}
export async function getResourceById (id: number): Promise<any> {
  const response = await apiCaller({
    url: `${dbOperatorUrl}/resource/${id}`,
    method: 'GET'
  })
  return response.json()
}

export async function getApprovals (): Promise<any> {
  const response = await apiCaller({
    url: `${dbOperatorUrl}/approval`,
    method: 'GET'
  })
  return response.json()
}
/**
 *
 * @returns
 */
export async function getPlatforms (): Promise<any> {
  try {
    const response = await apiCaller({
      url: `${dbOperatorUrl}/platform`,
      method: 'GET'
    })

    const data = await response.json()

    if (Array.isArray(data)) {
      // Sorting the data array by the 'buName' property
      data.sort((a, b) => a.buName.localeCompare(b.buName))
      return data
    } else {
      // The case when data is not an array
      return []
    }
  } catch (error) {
    console.log('db operator for platform values call has failed', error)
    return []// api failure should not prevent page from loading
  }
}

export async function getPlatformSysid (queryParams: Record<string, number | string>): Promise<any> {
  try {
    const url = `${dbOperatorUrl}/snplatform`
    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&')
    const fullUrl = `${url}?${queryString}`

    const response = await apiCaller({
      url: fullUrl,
      method: 'GET'
    })

    return response.json()
  } catch (error) {
    console.log('DB operator failed while fetching values', error)
    return []
  }
}

/**
 *
 * @returns
 */
export async function getServices (): Promise<any> {
  try {
    const response = await apiCaller({
      url: `${dbOperatorUrl}/snservice`,
      method: 'GET'
    })

    return response.json()
  } catch (error) {
    console.log('db operator for platform values call has failed', error)
    return []// api failure should not prevent page from loading
  }
}

export async function getRelationshipDetails (queryParams: Record<string, number | string>): Promise<any> {
  try {
    const url = `${dbOperatorUrl}/snrelationship`
    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&')
    const fullUrl = `${url}?${queryString}`

    const response = await apiCaller({
      url: fullUrl,
      method: 'GET'
    })

    return response.json()
  } catch (error) {
    console.log('DB operator failed while fetching values', error)
    return []
  }
}

export async function getApplicationDetails (queryParams: Record<string, number | string>): Promise<any> {
  try {
    const url = `${dbOperatorUrl}/snapplication`
    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&')
    const fullUrl = `${url}?${queryString}`

    const response = await apiCaller({
      url: fullUrl,
      method: 'GET'
    })

    return response.json()
  } catch (error) {
    console.log('DB operator failed while fetching values', error)
    return []
  }
}

/**
 *
 * @param string
 * @returns
 */
export async function getUserMappingDetails (email: string): Promise<any> {
  try {
    const response = await apiCaller({
      url: `${dbOperatorUrl}/user?email=${email}`,
      method: 'GET'
    })

    const responsePayload = await response.json()
    const userMap = new Map<string, any[]>()

    responsePayload?.resources.forEach((element: Resource) => {
      const resourceArray = userMap.has(element.requestType) ? userMap.get(element.requestType) : [] as any[]
      resourceArray?.push(element.resourceDetails)

      if (resourceArray !== undefined) {
        userMap.set(element.requestType, resourceArray)
      }
    })
    if (responsePayload !== null) {
      userMap.set('User_id', responsePayload.id)
    }
    return userMap === null ? new Map<string, any[]>() : userMap
  } catch (error) {
    console.log('user mapping call has failed', error)
    const userMap = new Map<string, any[]>()
    return userMap
    // api failure should not prevent page from loading
  }
}

export async function getUserDetailsById (id: number): Promise<boolean | null> {
  try {
    const response = await apiCaller({
      url: `${dbOperatorUrl}/user/${id}`,
      method: 'GET'
    })
    const userDetails = await response.json()

    if (!userDetails) {
      throw new Error(`User with ID ${id} not found`)
    }
    // Convert exptsandcsread to boolean
    const exptsAndCsRead = userDetails.exptsandcsread === 'yes'
    return exptsAndCsRead
  } catch (error) {
    console.error('Error fetching user details:', error)
    return null
  }
}

export async function getUserDetailsByEmail (email: string): Promise<any> {
  try {
    const response = await apiCaller({
      url: `${dbOperatorUrl}/user?email=${email}`,
      method: 'GET'
    })

    const userDetails = await response.json()

    if (!userDetails) {
      throw new Error(`User with email ${email} not found`)
    }
    return userDetails.id
  } catch (error) {
    console.error('Error fetching user details by email:', error)
    return null
  }
}

/**
 *
 * @param string
 * @returns
 */
export async function getGcpNetworkDetails (platform: string): Promise<any> {
  try {
    const response = await apiCaller({
      url: `${dbOperatorUrl}/gcpnetworks?platform=${platform}`,
      method: 'GET'
    })

    const responsePayload = await response.json()
    return responsePayload
  } catch (error) {
    console.log('gcp networks  call has failed', error)
    return []
  }
}
/**
 *
 * @param string
 * @returns
 */
export async function getServiceQueryDetails (queryParams: Record<string, number | string>): Promise<any> {
  try {
    const url = `${dbOperatorUrl}/snservice`
    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&')
    const fullUrl = `${url}?${queryString}`

    const response = await apiCaller({
      url: fullUrl,
      method: 'GET'
    })

    return response.json()
  } catch (error) {
    console.log('DB operator failed while fetching values', error)
    return []
  }
}

// Get Azure Subscription Details based on the subscription <name>
export async function getAzureSubscriptionSysId (queryParams: Record<string, number | string>): Promise<any> {
  try {
    const url = `${dbOperatorUrl}/snazuresubscription`
    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&')
    const fullUrl = `${url}?${queryString}`

    const response = await apiCaller({
      url: fullUrl,
      method: 'GET'
    })

    return response.json()
  } catch (error) {
    console.log('DB operator failed while fetching values', error)
    return []
  }
}

// Get Azure Subscription Details based on the subscription <name>
export async function updateResourceTable (inputpayload: any, id: number): Promise<any> {
  try {
    const url = `${dbOperatorUrl}/resource/${id}`

    const response = await apiCaller({
      url,
      method: 'PATCH',
      data: JSON.stringify(inputpayload)
    })

    return response.json()
  } catch (error) {
    console.log('DB operator failed while fetching values', error)
    return []
  }
}

export async function updateUserApproval (id: number): Promise<any> {
  try {
    const url = `${dbOperatorUrl}/user/${id}`
    const inputPayload = {
      expts_and_csread: 'yes'
    }

    const response = await apiCaller({
      url,
      method: 'PATCH',
      data: JSON.stringify(inputPayload)
    })

    return response.json()
  } catch (error) {
    console.log('DB operator failed while fetching values', error)
    return []
  }
}

export async function getAllReport (): Promise<any> {
  try {
    const response = await apiCaller({
      url: `${dbOperatorUrl}/report`,
      method: 'GET'
    })

    const reportDetails = await response.json()

    if (reportDetails === null) {
      throw new Error('No data found')
    } else {
      return reportDetails
    }
  } catch (error) {
    console.log('DB operator failed while fetching values', error)
    return []
  }
}

export async function getReport (userId: number): Promise<any> {
  try {
    const response = await apiCaller({
      url: `${dbOperatorUrl}/report?userId=${userId}`,
      method: 'GET'
    })

    const reportDetails = await response.json()

    if (!reportDetails) {
      throw new Error(`User with ID ${userId} not found`)
    } else {
      return reportDetails
    }
  } catch (error) {
    console.log('DB operator failed while fetching values', error)
    return []
  }
}

export async function getPlatformContact (platformName: string, mode: number): Promise<any> {
  try {
    const response = await apiCaller({
      url: `${dbOperatorUrl}/subscription-audit?platformName=${platformName}&mode=${mode}`,
      method: 'GET'
    })

    const contactDetails = await response.json()

    if (!contactDetails) {
      throw new Error(`Platform with name, ${platformName}, not found`)
    } else {
      return contactDetails
    }
  } catch (error) {
    console.log('DB operator failed while fetching values', error)
    return []
  }
}

export async function getSubProjAudit (): Promise<any> {
  try {
    const response = await apiCaller({
      url: `${dbOperatorUrl}/subscription-audit`,
      method: 'GET'
    })

    return response.json()
  } catch (error) {
    console.log('db operator for platform values call has failed', error)
    return []// api failure should not prevent page from loading
  }
}

export async function patchSubProjAudit (data: any, id: number): Promise<any> {
  try {
    const url = `${dbOperatorUrl}/subscription-audit/${id}`

    const response = await apiCaller({
      url,
      method: 'PATCH',
      data: JSON.stringify(data)
    })

    return response.json()
  } catch (error) {
    console.log('DB operator failed while patching values', error)
    return []
  }
}

export async function getPlatformsMgmt (platformId: number): Promise<any> {
  try {
    const response = await apiCaller({
      url: `${dbOperatorUrl}/platform/${platformId}`,
      method: 'GET'
    })

    const data = await response.json()

    return data
  } catch (error) {
    console.log('Error fetching platform by ID:', error)
    return null
  }
}

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> TESTING LOAD / SAVE feature

export async function saveBuildSheet (request: SolutionDesign | SolutionDesignGcp): Promise<any> {
  try {
    // Log the request payload
    // console.log('Saving build sheet with request:', JSON.stringify(request, null, 2))

    const response = await apiCaller({
      url: `${dbOperatorUrl}/solution-design`,
      method: 'POST',
      data: JSON.stringify(request, null, 2)
    })

    // Log the response status
    // console.log('Response status:', response.status)

    if (!response.ok) {
      // Log the full error details including status and response body
      const errorResponse = await response.json()
      console.error('Failed to save build sheet. Response details:', {
        status: response.status,
        statusText: response.statusText,
        errorResponse
      })
      throw new Error(`Failed to save: ${response.statusText}`)
    }

    // Log the response body
    const jsonResponse = await response.json()
    // console.log('Response data:', JSON.stringify(jsonResponse, null, 2))

    return jsonResponse
  } catch (error: any) {
    // Log both the error message and any additional error details
    console.error('Error saving build sheet:', error.message)
    console.error('Detailed error:', error)

    throw error // Rethrow to allow handling at a higher level if needed
  }
}

export async function loadSolutionList (): Promise<any> {
  try {
    const response = await apiCaller({
      url: `${dbOperatorUrl}/solution-design`, // Assuming this returns all designs
      method: 'GET'
    })

    if (!response.ok) {
      throw new Error(`Failed to load solution designs: ${response.statusText}`)
    }

    const jsonResponse = await response.json()

    // Filter the response to only include id and solution_name
    const filteredSolutions = jsonResponse.map((design: any) => ({
      id: design.id,
      solution_name: design.solutionName,
      cspType: design.cspType,
      remarks: design.remarks,
      solutionStatus: design.solutionStatus
    }))

    return filteredSolutions // Return filtered solution list
  } catch (error: any) {
    console.error('Error loading solution designs:', error.message)
    throw error
  }
}

export async function loadBuildSheet (id: string): Promise<any> {
  try {
    const response = await apiCaller({
      url: `${dbOperatorUrl}/solution-design/${id}`, // Fetch detailed data for selected solution
      method: 'GET'
    })

    if (!response.ok) {
      throw new Error(`Failed to load solution design: ${response.statusText}`)
    }

    const jsonResponse = await response.json()

    // console.log(jsonResponse)

    return jsonResponse // Return detailed data
  } catch (error: any) {
    console.error('Error loading solution design:', error.message)
    throw error
  }
}

export async function updateBuildSheet (id: number, payload: any): Promise<any> {
  try {
    // Structure the data as required
    // const dataToSend = {
    //   payload // Wrap payload within a 'payload' field
    // }

    const dataToSend = {
      payload: payload.formData, // Map formData to payload column
      remarks: payload.remarks // Map remarks to remarks column
    }

    // console.log('Updating build sheet with request:', JSON.stringify(dataToSend, null, 2))

    const response = await apiCaller({
      url: `${dbOperatorUrl}/solution-design/${id}`, // id is a number here
      method: 'PATCH',
      data: JSON.stringify(dataToSend)
    })

    // console.log('Response status: ', response.status)

    if (!response.ok) {
      const errorResponse = await response.json()
      console.error('Failed to update build sheet. Response details:', {
        status: response.status,
        statusText: response.statusText,
        errorResponse
      })
      throw new Error(`Failed to update: ${response.statusText}`)
    }

    const jsonResponse = await response.json()
    // console.log('Response data:', JSON.stringify(jsonResponse, null, 2))

    return jsonResponse
  } catch (error: any) {
    console.error('Error updating build sheet:', error.message)
    console.error('Detailed error:', error)
    throw error
  }
}

// export async function updateSolutionStatus (id: string, status: string): Promise<void> {
//   try {
//     await apiCaller({
//       url: `${dbOperatorUrl}/solution/${id}/status`, // Adjust the URL as needed
//       method: 'PATCH',
//       data: JSON.stringify({ solution_status: status }) // Send the new status
//     })
//   } catch (error) {
//     console.error('Error updating solution status:', error)
//     // Handle error as needed (e.g., log, alert user)
//   }
// }

export async function getAllAnalytics (): Promise<any> {
  const response = await apiCaller({
    url: `${dbOperatorUrl}/analytic`,
    method: 'GET'
  })
  // return analyticData
  return response.json()
}
export async function getPaveViewAnalytics (isIncludeDev:any): Promise<any> {
  const response = await apiCaller({
    url: `${dbOperatorUrl}/analytic?analytics=pageview&includeDev=${isIncludeDev}`,
    method: 'GET'
  })
  return response.json()
}
export async function getSessionAnalytics (isIncludeDev:any): Promise<any> {
  const response = await apiCaller({
    url: `${dbOperatorUrl}/analytic?analytics=session&includeDev=${isIncludeDev}`,
    method: 'GET'
  })
  return response.json()
}
export async function getUserMatrics (isIncludeDev:any): Promise<any> {
  const response = await apiCaller({
    url: `${dbOperatorUrl}/analytic?analytics=user&includeDev=${isIncludeDev}`,
    method: 'GET'
  })
  return response.json()
}
export async function getAnalyticsById (id: number): Promise<any> {
  const response = await apiCaller({
    url: `${dbOperatorUrl}/analytic/${id}`,
    method: 'GET'
  })

  return response.json()
}
export async function getAnalyticsByParam (userName: string, pageName: string): Promise<any> {
  const response = await apiCaller({
    url: `${dbOperatorUrl}/analytic?user_name=${userName}&page_name=${pageName}`,
    method: 'GET'
  })

  return response.json()
}

export async function createAnalytics (payload: Analytic): Promise<any> {
  const response = await apiCaller({
    url: `${dbOperatorUrl}/analytic`,
    method: 'POST',
    data: JSON.stringify(payload)
  })
  return response.json()
}

export async function updateAnalytics (payload: any, id: number): Promise<any> {
  try {
    const url = `${dbOperatorUrl}/analytic/${id}`

    const response = await apiCaller({
      url,
      method: 'PATCH',
      data: JSON.stringify(payload)
    })

    return response.json()
  } catch (error) {
    console.log('DB operator failed while fetching values', error)
    return {}
  }
}

export async function getNotificationsById (userId: number): Promise<boolean | null> {
  // console.log('Entering getNotificationsById')
  try {
    const response = await apiCaller({
      url: `${dbOperatorUrl}/notification/user/${userId}`,
      method: 'GET'
    })
    const userNotifications = await response.json()

    // console.log('User notifications:', userNotifications)

    if (!userNotifications) {
      throw new Error(`User with ID ${userId} not found`)
    }

    return userNotifications
  } catch (error) {
    console.error('Error fetching user details:', error)
    return null
  }
}

export async function softDeleteNotificationsByNotificationId (notificationId: number, isRead: boolean): Promise<boolean | null> {
  // console.log('Entering softDeleteNotificationsByNotificationId')
  try {
    const response = await apiCaller({
      url: `${dbOperatorUrl}/notification/id/${notificationId}`,
      method: 'PATCH',
      data: JSON.stringify({ is_read: isRead })
    })

    // Check if the response status is not OK
    if (!response.ok) {
      const errorDetails = await response.json()
      console.error('Failed to soft delete notification:', {
        status: response.status,
        statusText: response.statusText //,
        // errorDetails
      })
      throw new Error(`Failed to soft delete notification with ID ${notificationId}: ${response.statusText}`)
    }

    const softDeleteNotification = await response.json()
    // console.log('Notifications soft deleted successfully:', softDeleteNotification)

    if (!softDeleteNotification) {
      throw new Error(`Notification with ID ${notificationId} not found`)
    }

    return softDeleteNotification
  } catch (error: any) {
    // Handle network errors or unexpected issues
    if (error.response) {
      console.error('API responded with an error:', {
        status: error.response.status,
        data: error.response.data
      })
    } else if (error.request) {
      console.error('No response received from the API:', error.request)
    } else {
      console.error('Unexpected error occurred:', error.message)
    }

    console.error('Error details:', error)
    return null
  }
}
